import { Injectable } from '@angular/core';
import { MSAObject, Replay, ReplayChunk } from 'src/app/core/domain';
import { AddChunkMessage, GetObjectsAtTimeMessage } from 'src/app/core/utils/worker/replay/replay-worker.types';
import { WorkerMessengerService } from 'src/app/core/utils/worker/worker-messenger.service';

@Injectable({
  providedIn: 'root',
})
export class ReplayWorkerMessengerService extends WorkerMessengerService {
  async sendChunk(replay: Replay, layerId: string, chunk: ReplayChunk, objects: MSAObject[]) {
    const message: AddChunkMessage = {
      operation: 'addChunk',
      data: {
        layerId,
        chunksDefinition: replay.Chunks,
        chunk: chunk,
        objects,
      },
    };
    await this.postMessageSync(message);
  }

  async getObjectsAtTime(layerId: string, date: string): Promise<{ [id: string]: MSAObject }> {
    const message: GetObjectsAtTimeMessage = {
      operation: 'getObjectsAtTime',
      data: {
        layerId,
        date,
      },
    };
    return this.postMessageSync(message);
  }
}
